import {
  CSSProperties,
  Dispatch,
  MutableRefObject,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  ActionButton,
  FontSizes,
  Icon,
  IconButton,
  mergeStyles,
  MessageBarType,
  NeutralColors,
  PrimaryButton,
  ProgressIndicator,
  SharedColors,
  Stack,
  Text,
  TextField,
} from '@fluentui/react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import {
  PLAYBOOK_INSTRUCTION_ADDED_BY_USER,
  PlaybookBuilderContentResponse,
  PlaybookMetadata,
  PlaybookResource,
  playbookStatusConfig,
  Resource,
  type PatchPlaybookRequest,
  type PatchPlaybookResponse,
  type PlaybookBuilderStructuredOutput,
  type PlaybookContent,
  type PlaybookContractInstruction,
} from '@blaw/contracts-api-schema'
import { defaultCollapsbleIconStyles } from '@components/CollapsibleItem'
import { deleteBtnStyles } from '@modules/sharedStyles'
import { StoreContext } from '@contexts/StoreContext'
import { StyledDivider } from '@baseComponents/StyledDivider'
import { parseJSON } from '@modules/utils'
import { itemClicked, useContractTaskPaneViewed } from '@modules/analytics'
import { useTranslation } from '@hooks/useTranslation'
import AddAnotherTextField from '@components/AddAnotherTextField'
import ApiClient from '@modules/ApiClient'
import BoldText from '@baseComponents/BoldText'
import ConfirmDialog from '@components/ConfirmDialog'
import ContentCard from '@components/ContentCard'
import InlineToggle from '@baseComponents/InlineToggle'
import NotificationBadge from '@components/NotificationBadge'
import ShowMore from '@components/ShowMore'
import SmallButton from '@components/SmallButton'
import StatusBadge from '@components/StatusBadge'
import StyledStack from '@components/StyledStack'
import TopNav from '@components/TopNav'
import UnstyledList from '@baseComponents/UnstyledList'
import LoadingShimmer from '@components/LoadingShimmer'
import ModalProgress from '@components/ModalProgress'
import { PlaybooksContext } from '@contexts/PlaybooksContext'
import DeletePlaybookForm from '@components/DeletePlaybookForm'
import ErrorMessage from '@components/ErrorMessage'
import { getPlaybookStatusColor } from '@modules/Playbook'
import useScrollToTop from '@hooks/useScrollToTop'
import StyledTextField from '@components/StyledTextField'

type MergedPlaybookContent = PlaybookContent & { name: string; metadata: PlaybookMetadata }
type PlaybookState = {
  name: string
  contractType: string
  playbookPartyRole: string
  templateName?: string
  playbookContent: PlaybookContent
}
type TemplateResponse = {
  response: Resource
}

// TODO: move to api schema when integrating the frontend
type GetPlaybookResponse = {
  playbook?: PlaybookResource
  content?: PlaybookContent
  error?: string
}

const contentCardStyles: CSSProperties = { padding: '0.7em 0.7em 0 0.7em' }
const contentTitleStyles: CSSProperties = { fontSize: FontSizes.large }
const toolbarStyles: CSSProperties = { display: 'flex', alignItems: 'center', gap: '0.3em' }
const labelStyles = { marginBottom: '0.5em', display: 'block' }
const EMPTY_CLAUSE_ALTERNATE = 'None Found'
export const CONTENT_VALIDATION_ERROR =
  'There is insufficient content in this Playbook to publish. Please add at least 3 instructions.'

export default function EditPlaybook() {
  const { playbookId } = useParams()
  const [params] = useSearchParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { storeSessionInfo, routes } = useContext(StoreContext)
  const { setDeleteModalHidden, deleteModalHidden, deleting } = useContext(PlaybooksContext)
  const [loading, setLoading] = useState(true)
  const [hideProgress, setHideProgress] = useState(true)
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState('')
  const [progressMessage, setProgressMessage] = useState('')
  const [playbook, setPlaybook] = useState({} as PlaybookState)
  const [templateName, setTemplateName] = useState('')
  const [metadata, setMetadata] = useState({} as PlaybookMetadata)
  const [contractInstructions, setContractInstructions] = useState(
    [] as PlaybookContractInstruction[],
  )
  const [clauseContent, setClauseContent] = useState([] as PlaybookBuilderContentResponse)
  const [isValidPlaybook, setIsValidPlaybook] = useState(false)
  const [newInstructionDialogHidden, setNewInstructionDialogHidden] = useState(true)
  const [newClauseDialogHidden, setNewClauseDialogHidden] = useState(true)
  const [deleteInstructionDialogHidden, setDeleteInstructionDialogHidden] = useState(true)
  const [selectedInstructionIndex, setSelectedInstructionIndex] = useState(-1)
  const [deleteClauseDialogHidden, setDeleteClauseDialogHidden] = useState(true)
  const [publishPlaybookConfirmationHidden, setPublishPlaybookConfirmationHidden] = useState(true)
  const [selectedClauseIndex, setSelectedClauseIndex] = useState(-1)
  const [lastEditedClause, setLastEditedClause] = useState<PlaybookBuilderStructuredOutput>()
  const clauseAlternatesToRemove = useRef<number[]>([])
  const { topOfPageRef, scrollToTop } = useScrollToTop()

  const pageTitle = t('page.Playbook Details.Title')
  const newCompliancePath = `/playbooks/${playbookId}/compliance/new?title=${playbook.name}&type=${playbook.contractType}&role=${playbook.playbookPartyRole}`
  const apiClient = new ApiClient(storeSessionInfo, setError)
  let originalContractInstructions: string[] = []

  const [debug, setDebug] = useState<any>({})

  useContractTaskPaneViewed({ pageTitle, eventDetails: [playbookId ?? 'N/A'] })

  useEffect(() => {
    const fetchPlaybookData = async () => {
      if (!playbookId) return

      setLoading(true)
      setError('')

      try {
        const playbookResponse = await loadPlaybook(playbookId)
        const templateIds = setPlaybookState(playbookResponse)

        const id = templateIds?.[0]
        // User can create an empty playbook without id
        if (id) {
          const { data } = await apiClient.get<TemplateResponse>(routes.getResourceUrl(id))
          setTemplateName(data.response.name)
        }
      } catch (e) {
        setError((e as Error).message)
        console.error(e)
      } finally {
        setLoading(false)
      }
    }

    fetchPlaybookData()
  }, [])

  if (!playbookId) throw Error('Missing playbook ID')

  if (loading)
    return (
      <Layout>
        <ProgressIndicator label={`Loading ${params.get('name') ?? ''}...`} />
        <LoadingShimmer />
      </Layout>
    )

  const playbookStatus = metadata.userMetadata.playbook_status || 'DRAFT'
  const playbookStatusLabel = playbookStatusConfig.getPlaybookStatusLabel(playbookStatus)

  return (
    <Layout>
      <Stack.Item>
        <ContentCard
          title={t('page.Contracts.General Information')}
          style={contentCardStyles}
          titleStyle={contentTitleStyles}
        >
          <StatusBadge
            status={playbookStatusLabel}
            badgeColor={getPlaybookStatusColor(playbookStatus)}
          />
          <Metadata
            editable
            name={t('page.Playbook Builder.Playbook Name')}
            values={playbook.name}
            saving={saving}
            onChange={updatePlaybookName}
            dialogHiddenDefault={true}
          >
            {playbook.name}
          </Metadata>
          <Metadata name="Contract Type" values={playbook.contractType} dialogHiddenDefault={true}>
            {playbook.contractType}
          </Metadata>
          <Metadata
            name="Party Role"
            values={playbook.playbookPartyRole}
            dialogHiddenDefault={true}
          >
            {playbook.playbookPartyRole}
          </Metadata>
          {templateName && (
            <Metadata name="Source Documents" values={templateName} dialogHiddenDefault={true}>
              {templateName}
            </Metadata>
          )}
        </ContentCard>
      </Stack.Item>

      <Stack.Item>
        <ContentCard
          title={t('page.Playbook Details.Contract Instructions')}
          style={contentCardStyles}
          titleStyle={contentTitleStyles}
        >
          {contractInstructions.map((entry, i) => {
            const userAdded = entry.source === PLAYBOOK_INSTRUCTION_ADDED_BY_USER
            const toggleLabel = `Use ${userAdded ? '' : 'as'} instruction`
            const color = userAdded ? 'cyan10' : 'magenta20'
            const badge = userAdded
              ? t('page.Playbook Details.Manually added')
              : t('page.Playbook Details.Extracted from template')

            return (
              <Metadata
                editable
                deletable
                multiline
                key={`${i}-${entry.instruction}`}
                values={entry.instruction}
                saving={saving}
                name={`${typeOfInstruction(entry.added)} ${i + 1}`}
                unsaved={
                  !entry.instruction || !originalContractInstructions.includes(entry.instruction)
                }
                dialogHiddenDefault={!!entry.instruction || saving}
                onRemove={() => confirmRemoveContractInstruction(i)}
                onChange={v => updateContractInstructions(i, v)}
              >
                <StatusBadge
                  status={badge}
                  badgeColor={SharedColors[color]}
                  style={{ margin: '0.3em 0 0.5em 0' }}
                />
                <ShowMore
                  content={entry.instruction}
                  style={{ marginBottom: '0.5em', display: 'inline-block' }}
                />
                <InlineToggle
                  label={toggleLabel}
                  checked={entry.added}
                  disabled={saving}
                  onChange={async () => {
                    entry.added = !entry.added
                    await submit({
                      metadata,
                      name: playbook.name,
                      clauses: clauseContent,
                      contract_instructions: contractInstructions,
                    })
                    setContractInstructions([...contractInstructions])
                  }}
                />
                {/* TODO: template_citation might be needed for post MVP */}
                {/* {entry.template_citation?.trim() && (
                  <UnstyledList style={{ margin: '0.3em 0' }}>
                    <CollapsibleItem
                      key={i}
                      item={{
                        key: entry.template_citation,
                        children: entry.template_citation,
                        active: false,
                      }}
                      itemHeader={() => 'Template Citation'}
                      itemContent={() => (
                        <TemplateCitation content={entry.template_citation ?? ''} />
                      )}
                    />
                  </UnstyledList>
                )} */}
                <StyledDivider />
              </Metadata>
            )
          })}

          <Stack.Item style={{ display: 'flex', justifyContent: 'end' }}>
            <ActionButton
              iconProps={{ iconName: 'Add' }}
              style={{ marginTop: '0.3em' }}
              onClick={() => setNewInstructionDialogHidden(false)}
            >
              Add Custom Instruction
            </ActionButton>
          </Stack.Item>
        </ContentCard>
      </Stack.Item>

      <Stack.Item>
        <ContentCard
          title={t('page.Playbook Details.Clause Content')}
          style={contentCardStyles}
          titleStyle={contentTitleStyles}
        >
          <UnstyledList>
            {clauseContent.map((clause, i) => (
              <ClauseMetadata
                editable
                deletable
                multiline
                key={i + JSON.stringify(clause)}
                values={clause}
                name={clause.clause_title}
                saving={saving}
                dialogHiddenDefault={!!clause.clause_title || saving}
                editDialogTitle={t('page.Playbook Details.Clause Content')}
                showContentDivider={i < clauseContent.length - 1}
                collapsedContent={
                  <ClauseContent
                    key={i}
                    clause={clause}
                    highlight={lastEditedClause?.clause_title === clause.clause_title}
                  />
                }
                defaultCollapsed={
                  !lastEditedClause || clause.clause_title !== lastEditedClause.clause_title
                }
                onChange={values => updateClause(values, i, clauseAlternatesToRemove)}
                innerStyle={{ padding: '0.3em 0 0.7em 0' }}
                setLastEditedClause={setLastEditedClause}
                onRemoveClauseAlternate={i => clauseAlternatesToRemove.current.push(i)}
                onRemove={() => confirmRemoveClauseContent(i)}
              >
                {findAlternateClauses(clause).length > 0 && <ClauseContentHeader clause={clause} />}
              </ClauseMetadata>
            ))}
          </UnstyledList>
          <Stack.Item style={{ display: 'flex', justifyContent: 'end' }}>
            <ActionButton
              iconProps={{ iconName: 'Add' }}
              style={{ marginTop: '0.3em' }}
              onClick={() => setNewClauseDialogHidden(false)}
            >
              Add New Clause
            </ActionButton>
          </Stack.Item>
        </ContentCard>
      </Stack.Item>

      <Stack.Item style={toolbarStyles}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          {playbookStatus === 'PUBLISHED' ? (
            <PrimaryButton
              onClick={() => navigate(newCompliancePath)}
              style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.5em' }}
            >
              {t('button.Check Compliance')}
            </PrimaryButton>
          ) : (
            <PrimaryButton
              onClick={() => setPublishPlaybookConfirmationHidden(false)}
              disabled={!isValidPlaybook}
              style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.5em' }}
            >
              {t('button.Publish')}
            </PrimaryButton>
          )}
        </div>

        <IconButton
          title={`${t('button.Delete')} ${playbook.name}`}
          disabled={saving || deleting}
          iconProps={{ iconName: 'Delete' }}
          onClick={() => setDeleteModalHidden(false)}
          style={{ color: SharedColors.red20 }}
        />
        <DeletePlaybookForm
          id={playbookId}
          name={playbook.name}
          hidden={deleteModalHidden}
          setDeleteModalHidden={setDeleteModalHidden}
          onDeleted={() => navigate(`/playbooks`)}
        />
      </Stack.Item>

      <NewInstructionDialog
        hidden={newInstructionDialogHidden}
        onDismiss={() => setNewInstructionDialogHidden(true)}
        onConfirm={value => createNewInstruction(value)}
      />

      <NewClauseDialog
        saving={saving}
        hidden={newClauseDialogHidden}
        setHidden={setNewClauseDialogHidden}
        onDismiss={() => setNewClauseDialogHidden(true)}
        onConfirm={value => createNewClause(value, clauseAlternatesToRemove)}
        onRemoveClauseAlternate={i => clauseAlternatesToRemove.current.push(i)}
        setLastEditedClause={setLastEditedClause}
      />

      <ConfirmDialog
        hidden={deleteInstructionDialogHidden}
        title={t('label.Confirm Delete')}
        confirm={`${t('button.Delete')} ${t('label.Instruction')}`}
        btnStyles={deleteBtnStyles}
        onDismiss={() => setDeleteInstructionDialogHidden(true)}
        onConfirm={() => removeContractInstruction(selectedInstructionIndex)}
      >
        <BoldText>{selectedInstructionLabel()}</BoldText>
        <ShowMore
          content={contractInstructions[selectedInstructionIndex]?.instruction ?? ''}
          style={{ display: 'block', marginTop: '0.3em' }}
        />
      </ConfirmDialog>

      <ConfirmDialog
        hidden={deleteClauseDialogHidden}
        title={t('label.Confirm Delete')}
        confirm={`${t('button.Delete')} ${t('page.Playbook Details.Clause Content')}`}
        btnStyles={deleteBtnStyles}
        onDismiss={() => setDeleteClauseDialogHidden(true)}
        onConfirm={() => removeClauseContent(selectedClauseIndex)}
      >
        <BoldText style={labelStyles}>Clause Title</BoldText>
        <ShowMore
          content={clauseContent[selectedClauseIndex]?.clause_title}
          style={{ display: 'block', marginTop: '0.3em', marginBottom: '0.3em' }}
        />
        <BoldText style={labelStyles}>Preferred Language</BoldText>
        <ShowMore
          content={
            clauseContent[selectedClauseIndex]?.sample_standard_language_acceptable_position ?? ''
          }
          style={{ display: 'block', marginTop: '0.3em' }}
        />
      </ConfirmDialog>
      <ConfirmDialog
        hidden={publishPlaybookConfirmationHidden}
        title={t('label.Confirm Publish')}
        confirm={`${t('button.Publish')}`}
        onDismiss={() => setPublishPlaybookConfirmationHidden(true)}
        onConfirm={() => publishPlaybook()}
      >
        Please confirm you want to publish this playbook.
      </ConfirmDialog>
      <ModalProgress
        title={error ? 'An error occurred' : progressMessage}
        hidden={hideProgress}
        error={error}
        cancelBtn={error ? 'Close' : false}
        onDismiss={() => location.reload()}
      />
    </Layout>
  )

  function Layout({ children }: PropsWithChildren) {
    return (
      <div ref={topOfPageRef}>
        <TopNav title={pageTitle} prevPath="#/playbooks" />
        {!loading && !isValidPlaybook && (
          <ErrorMessage message={CONTENT_VALIDATION_ERROR} type={MessageBarType.info} />
        )}
        <StyledStack>{children}</StyledStack>
      </div>
    )
  }

  async function loadPlaybook(id: string) {
    const { data } = await apiClient.get<GetPlaybookResponse>(routes.playbookUrl(id))
    setDebug({ ...debug, loadPlaybook: 1 })
    return data
  }

  function setPlaybookState(playbookResponse: GetPlaybookResponse) {
    const { playbook, content } = playbookResponse

    if (!(playbook && content)) throw Error('Failed to load Playbook')
    const parsedContent = parseJSON<PlaybookContent>(content)
    const name = playbook.name
    const contractType = playbook.metadata.userMetadata.contract_type
    const playbookPartyRole = playbook.metadata.userMetadata.playbook_party_role
    const playbookStatus = playbook.metadata.userMetadata.playbook_status
    const clauses = parsedContent.clauses || []
    const contract_instructions = parsedContent.contract_instructions || []
    originalContractInstructions = contract_instructions.map(({ instruction: i }) => i)
    const playbookData: PlaybookState = {
      name,
      contractType,
      templateName,
      playbookPartyRole,
      playbookContent: { clauses, contract_instructions },
    }

    setPlaybook(playbookData)
    setMetadata(playbook.metadata)
    setClauseContent(clauses)
    setContractInstructions(contract_instructions)
    setIsValidPlaybook(validatePlaybook({ playbookStatus, ...playbookData.playbookContent }))

    return playbook.metadata.userMetadata.playbook_template_ids
  }

  async function updatePlaybookName(values: string) {
    const name = values

    await submit({
      name,
      metadata,
      clauses: clauseContent,
      contract_instructions: contractInstructions,
    })
    setPlaybook({ ...playbook, name })
  }

  async function createNewInstruction(value: string) {
    const newInstruction = buildManuallyAddedInstruction(value)
    const updatedInstructions = [...contractInstructions, newInstruction]
    const content: PlaybookContent = {
      clauses: clauseContent,
      contract_instructions: updatedInstructions,
    }

    setNewInstructionDialogHidden(true)
    await submit({
      ...playbook,
      metadata,
      ...content,
    })
    setContractInstructions(updatedInstructions)
    setIsValidPlaybook(validatePlaybook({ playbookStatus, ...content }))
  }

  async function createNewClause(
    value: PlaybookBuilderStructuredOutput,
    clauseAlternatesToRemove: MutableRefObject<number[]>,
  ) {
    updateAlternative(value, clauseAlternatesToRemove)
    const updatedClause = [...clauseContent, value]
    setNewClauseDialogHidden(true)
    await submit({
      ...playbook,
      metadata,
      clauses: updatedClause,
      contract_instructions: contractInstructions,
    })
    setClauseContent(updatedClause)
    setIsValidPlaybook(true)
  }

  async function updateContractInstructions(i: number, values: string) {
    const newValues = structuredClone(contractInstructions)
    newValues[i].instruction = values
    const original = contractInstructions[i]
    const updated = newValues[i]
    const value = (values[0] ?? '').trim()
    const content: PlaybookContent = {
      clauses: clauseContent,
      contract_instructions: newValues,
    }

    updated.added = true
    updated.instruction = value
    if (original.instruction !== value) updated.source = PLAYBOOK_INSTRUCTION_ADDED_BY_USER

    await submit({
      ...playbook,
      metadata,
      ...content,
    })
    setContractInstructions(newValues)
    setIsValidPlaybook(validatePlaybook({ playbookStatus, ...content }))
  }

  async function confirmRemoveContractInstruction(i: number) {
    setSelectedInstructionIndex(i)
    setDeleteInstructionDialogHidden(false)
  }

  async function removeContractInstruction(i: number) {
    const newValues = withoutContractInstruction(i)

    setDeleteInstructionDialogHidden(true)
    await submit({
      ...playbook,
      metadata,
      clauses: clauseContent,
      contract_instructions: newValues,
    })
    setContractInstructions(newValues)
  }

  async function confirmRemoveClauseContent(i: number) {
    setSelectedClauseIndex(i)
    setDeleteClauseDialogHidden(false)
  }

  async function removeClauseContent(i: number) {
    const newValues = withoutClauseContent(i)

    setDeleteClauseDialogHidden(true)
    await submit({
      ...playbook,
      metadata,
      clauses: newValues,
      contract_instructions: contractInstructions,
    })
    setClauseContent(newValues)
  }

  function withoutContractInstruction(i: number) {
    const newValues = structuredClone(contractInstructions)
    newValues.splice(i, 1)
    return newValues
  }

  function withoutClauseContent(i: number) {
    const newValues = structuredClone(clauseContent)
    newValues.splice(i, 1)
    return newValues
  }

  function updateAlternative(
    clause: PlaybookBuilderStructuredOutput & { [key: string]: string },
    clauseAlternatesToRemove: MutableRefObject<number[]>,
  ) {
    const alternates = findAlternateClauses(clause)
    const toRemove = clauseAlternatesToRemove.current

    if (toRemove.length) {
      toRemove.forEach(i => {
        clause[`alternate_${i + 1}`] = EMPTY_CLAUSE_ALTERNATE
      })
      // handle edgecase where a user adds 2 alternates, then removes the first one, then tries
      // to remove the 2nd one. The index parameter won't match up with the alternate's key name
      if (alternates.length === 1 && toRemove.length === 1) {
        clause.alternate_2 = EMPTY_CLAUSE_ALTERNATE
      }
      clauseAlternatesToRemove.current = []
    }
  }

  async function updateClause(
    clause: PlaybookBuilderStructuredOutput & { [key: string]: string },
    index: number,
    clauseAlternatesToRemove: MutableRefObject<number[]>,
  ) {
    updateAlternative(clause, clauseAlternatesToRemove)
    clauseContent[index] = clause
    await submit({
      ...playbook,
      metadata,
      clauses: clauseContent,
      contract_instructions: contractInstructions,
    })
    setClauseContent([...clauseContent])
  }

  async function publishPlaybook() {
    const newMetadata = {
      ...metadata,
      userMetadata: { ...metadata.userMetadata, playbook_status: 'PUBLISHED' },
    }
    setPublishPlaybookConfirmationHidden(true)
    await submit(
      {
        ...playbook,
        metadata: newMetadata,
        clauses: clauseContent,
        contract_instructions: contractInstructions,
      },
      t('page.Playbook Details.Publishing your playbook'),
    )
    setMetadata(newMetadata)
    scrollToTop()
  }

  async function submit(
    { name, metadata, clauses, contract_instructions }: MergedPlaybookContent,
    message = t('label.Applying your changes'),
  ) {
    if (!playbookId) throw Error(`Missing playbookId parameter`)

    itemClicked({
      pageTitle: pageTitle,
      itemClicked: t('page.Playbook Details.Save Playbook'),
      itemLocation: 'Taskpane',
      itemType: 'Form Submit',
      isLoggedIn: true,
    })

    try {
      const apiClient = new ApiClient(storeSessionInfo, setError)
      const content = { clauses, contract_instructions }
      setSaving(true)
      setError('')
      setHideProgress(false)
      setProgressMessage(message)

      const payload: PatchPlaybookRequest = {
        content,
        playbook: { name, metadata },
      }
      await apiClient.patch<PatchPlaybookResponse>(routes.playbookUrl(playbookId), payload)

      setHideProgress(true)
    } catch (e) {
      console.error('e', e)
      setError((e as Error).message)
    } finally {
      setSaving(false)
    }
  }

  function typeOfInstruction(userAdded: boolean | undefined) {
    return t(`label.${userAdded ? 'Instruction' : 'Annotation'}`)
  }

  function selectedInstructionLabel() {
    const { added } = contractInstructions[selectedInstructionIndex] ?? {}
    return `${typeOfInstruction(added)} ${selectedInstructionIndex + 1}`
  }
}

type MetadataProps = PropsWithChildren & {
  name?: string
  values?: string
  saving?: boolean
  unsaved?: boolean
  editable?: boolean
  deletable?: boolean
  multiline?: boolean
  editDialogTitle?: string
  onChange?: (v: string) => Promise<void>
  onRemove?: () => void
  onDismiss?: (v: string) => void
  dialogHiddenDefault: boolean
  collapsedContent?: string | ReactNode
  defaultCollapsed?: boolean
  showContentDivider?: boolean
  style?: CSSProperties
  innerStyle?: CSSProperties
}

function Metadata(props: MetadataProps) {
  const { t } = useTranslation()
  const [dialogHidden, setDialogHidden] = useState<boolean>(props.dialogHiddenDefault ?? true)
  const [collapsed, setCollapsed] = useState(props.defaultCollapsed)
  const title = `${t(`button.${props.unsaved ? 'Add' : 'Edit'}`)} ${props.name}`
  const collapseIcon = collapsed ? 'ChevronRight' : 'ChevronDown'
  const cursor = props.collapsedContent ? 'pointer' : 'inherit'
  const collapsibleBoxClass = mergeStyles({
    ':hover, :active': { background: props.collapsedContent && NeutralColors.gray30 },
  })
  const borderBottom = props.showContentDivider ? `1px solid ${SharedColors.gray10}` : ''

  return (
    <>
      <MetadataContent
        setCollapsed={setCollapsed}
        collapsed={collapsed}
        cursor={cursor}
        borderBottom={borderBottom}
        collapsibleBoxClass={collapsibleBoxClass}
        collapseIcon={collapseIcon}
        title={title}
        name={props.name}
        editable={props.editable}
        deletable={props.deletable}
        setDialogHidden={setDialogHidden}
        onRemove={props.onRemove}
        dialogHiddenDefault={dialogHidden}
      >
        {props.children}
      </MetadataContent>

      <EditDialog
        title={props.editDialogTitle ?? title}
        label={props.name ?? ''}
        multiline={props.multiline}
        values={props.values ?? ''}
        saving={props.saving}
        hidden={dialogHidden}
        setHidden={setDialogHidden}
        onChange={props.onChange}
        onDismiss={props.onDismiss}
      />
    </>
  )
}

type ClauseMetadataProps = Omit<MetadataProps, 'values' | 'onChange' | 'onDismiss'> & {
  values: PlaybookBuilderStructuredOutput
  onChange: (values: PlaybookBuilderStructuredOutput) => void
  setLastEditedClause: Dispatch<SetStateAction<PlaybookBuilderStructuredOutput | undefined>>
  onRemoveClauseAlternate: (index: number) => void
}

function ClauseMetadata(props: ClauseMetadataProps) {
  const { t } = useTranslation()
  const [dialogHidden, setDialogHidden] = useState(props.dialogHiddenDefault)
  const [collapsed, setCollapsed] = useState(props.defaultCollapsed)
  const title = `${t(`button.${props.unsaved ? 'Add' : 'Edit'}`)} ${props.name}`
  const collapseIcon = collapsed ? 'ChevronRight' : 'ChevronDown'
  const cursor = props.collapsedContent ? 'pointer' : 'inherit'
  const collapsibleBoxClass = mergeStyles({
    ':hover, :active': { background: props.collapsedContent && NeutralColors.gray30 },
  })
  const borderBottom = props.showContentDivider ? `1px solid ${SharedColors.gray10}` : ''

  return (
    <>
      <MetadataContent
        setCollapsed={setCollapsed}
        collapsed={collapsed}
        cursor={cursor}
        borderBottom={borderBottom}
        collapsibleBoxClass={collapsibleBoxClass}
        collapseIcon={collapseIcon}
        collapsedContent={props.collapsedContent}
        title={title}
        name={props.name}
        editable={props.editable}
        deletable={props.deletable}
        setDialogHidden={setDialogHidden}
        onRemove={props.onRemove}
        dialogHiddenDefault={dialogHidden}
      >
        {props.children}
      </MetadataContent>
      <ClauseEditDialog
        confirmLabel={t('button.Apply Changes')}
        title={props.editDialogTitle ?? title}
        label={props.name ?? ''}
        multiline={props.multiline}
        values={props.values}
        saving={props.saving}
        hidden={dialogHidden}
        setHidden={setDialogHidden}
        onChange={props.onChange}
        setLastEditedClause={props.setLastEditedClause}
        onRemoveClauseAlternate={props.onRemoveClauseAlternate}
      />
    </>
  )
}

type TemplateCitationProps = {
  content: string
}

type MetadataContentProps = MetadataProps & {
  setDialogHidden: Dispatch<SetStateAction<boolean>>
  setCollapsed: Dispatch<SetStateAction<boolean | undefined>>
  onRemove?: Dispatch<SetStateAction<void | undefined>>
  collapsed: boolean | undefined
  title: string
  cursor: string
  borderBottom: string
  collapseIcon: string
  collapsibleBoxClass: string
}

function MetadataContent(props: MetadataContentProps) {
  return (
    <Stack style={props.style}>
      <Stack
        horizontal
        onClick={() => props.setCollapsed(!props.collapsed)}
        style={{
          alignItems: 'baseline',
          cursor: props.cursor,
          borderBottom: props.borderBottom,
          ...props.innerStyle,
        }}
        className={props.collapsibleBoxClass}
      >
        {props.collapsedContent && (
          <Icon
            iconName={props.collapseIcon}
            onClick={() => props.setCollapsed(!props.collapsed)}
            style={defaultCollapsbleIconStyles}
          />
        )}
        <div style={{ width: '100%' }}>
          <Stack horizontal style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <BoldText>{props.name}</BoldText>
            <Stack horizontal>
              {props.deletable && (
                <IconButton
                  iconProps={{ iconName: 'Delete' }}
                  onClick={props.onRemove}
                  style={{ color: SharedColors.red20 }}
                />
              )}
              <SmallButton
                title={props.title}
                onClick={() => props.setDialogHidden(false)}
                iconProps={{ iconName: 'Edit' }}
                style={{ visibility: props.editable ? 'visible' : 'hidden' }}
                ariaHidden={!props.editable}
              />
            </Stack>
          </Stack>
          {props.children}
        </div>
      </Stack>

      {props.collapsedContent && !props.collapsed && (
        <Stack style={{ marginBottom: '0.3em' }}>{props.collapsedContent}</Stack>
      )}
      {/* {props.showContentDivider && <StyledDivider />} */}
    </Stack>
  )
}

// TODO: template citation might be needed for post MVP
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function TemplateCitation({ content }: TemplateCitationProps) {
  return (
    <>
      <div style={{ padding: '0.7em', backgroundColor: NeutralColors.gray20 }}>
        <ShowMore content={content} />
      </div>
      <StyledDivider />
    </>
  )
}

type ClauseContentProps = {
  clause: PlaybookBuilderStructuredOutput
  highlight?: boolean
}

function ClauseContent({ clause, highlight }: ClauseContentProps) {
  const { t } = useTranslation()
  const alternates = findAlternateClauses(clause)

  return (
    <Stack style={{ gap: '0.5em', paddingLeft: '1.7em' }}>
      <Stack.Item style={{ padding: '0.3em' }}>
        <BoldText style={labelStyles}>Preferred Language</BoldText>
        <ShowMore
          content={clause.sample_standard_language_acceptable_position}
          style={{ display: 'block' }}
        />
      </Stack.Item>
      <Stack.Item style={{ padding: '0.3em' }}>
        <BoldText style={labelStyles}>Clause Summary</BoldText>
        <ShowMore content={clause.acceptable_position} style={{ display: 'block' }} />
      </Stack.Item>
      <Stack.Item style={{ padding: '0.3em' }}>
        <UnstyledList>
          {alternates.map(([_, alternate], i) => {
            return (
              <li
                key={i}
                className={highlight ? 'highlight' : ''}
                style={{ marginBottom: '0.8em' }}
              >
                <BoldText style={labelStyles}>
                  {t('page.Playbook Details.Alternate Clause')} {i + 1}
                </BoldText>
                <ShowMore content={alternate} style={{ display: 'block' }} />
              </li>
            )
          })}
        </UnstyledList>
      </Stack.Item>
    </Stack>
  )
}

type ClauseContentHeaderProps = {
  clause: PlaybookBuilderStructuredOutput
}

function ClauseContentHeader({ clause }: ClauseContentHeaderProps) {
  const { t } = useTranslation()

  return (
    <div style={{ marginBottom: '0.5em' }}>
      <Text>{t('page.Playbook Details.Alternative Clauses')}</Text>
      <NotificationBadge
        style={{
          marginLeft: '0.3em',
          backgroundColor: SharedColors.cyan10,
        }}
      >
        {findAlternateClauses(clause).length}
      </NotificationBadge>
    </div>
  )
}

type EditDialogProps = {
  title: string
  label: string
  values: string
  setHidden: Dispatch<SetStateAction<boolean>>
  onChange?: (v: string) => Promise<void>
  onDismiss?: (v: string) => void
  CustomEditor?: (props: EditorProps) => JSX.Element
  multiline?: boolean
  hidden?: boolean
  saving?: boolean
  clause?: PlaybookBuilderStructuredOutput
}
type EditorProps = {
  values: string
  setValues: Dispatch<SetStateAction<string>>
  onChange?: (v: string) => Promise<boolean>
}

function EditDialog(props: EditDialogProps) {
  const { t } = useTranslation()
  const [internalValue, setInternalValue] = useState(props.values)

  return (
    <ConfirmDialog
      title={props.title}
      hidden={props.hidden}
      disableBtn={props.saving}
      confirm={t('button.Apply Changes')}
      onConfirm={async () => {
        await props.onChange?.(internalValue)
        props.setHidden(true)
      }}
      onDismiss={() => {
        props.onDismiss?.(internalValue)
        props.setHidden(true)
      }}
    >
      <DefaultEditor values={internalValue} setValues={setInternalValue} />
    </ConfirmDialog>
  )

  function DefaultEditor({ values, setValues, onChange }: EditorProps) {
    const [internalValue, setInternalValue] = useState(values)

    return (
      <Stack.Item>
        <TextField
          onFocus={e => {
            const length = e.currentTarget.value.length
            e.currentTarget.setSelectionRange(length, length)
          }}
          autoFocus
          label={props.label}
          value={internalValue}
          disabled={props.saving}
          multiline={props.multiline}
          autoAdjustHeight={props.multiline}
          onChange={(_e, newValue) => {
            const value = newValue ?? ''
            onChange?.(value)
            setInternalValue(value)
          }}
          onBlur={() => setValues(internalValue)}
        />
      </Stack.Item>
    )
  }
}

type NewInstructionDialogProps = {
  hidden: boolean
  onDismiss: () => void
  onConfirm: (v: string) => Promise<void> | void
}

function NewInstructionDialog({ hidden, onDismiss, onConfirm }: NewInstructionDialogProps) {
  const { t } = useTranslation()
  const [value, setValue] = useState('')

  return (
    <ConfirmDialog
      hidden={hidden}
      title={`${t('button.Add')} ${t('label.Instruction')}`}
      confirm={t('button.Apply Changes')}
      onDismiss={onDismiss}
      onConfirm={() => onConfirm(value)}
    >
      <TextField
        autoFocus
        multiline
        autoAdjustHeight
        label={t('label.Instruction')}
        value={value}
        onChange={(_e, v) => setValue(v ?? '')}
      />
    </ConfirmDialog>
  )
}

type ClauseEditDialogProps = Omit<EditDialogProps, 'values' | 'onChange' | 'onDismiss'> & {
  values: PlaybookBuilderStructuredOutput
  confirmLabel: string
  onChange: (values: PlaybookBuilderStructuredOutput) => void
  onDismiss?: (values: PlaybookBuilderStructuredOutput) => void
  setLastEditedClause: Dispatch<SetStateAction<PlaybookBuilderStructuredOutput | undefined>>
  onRemoveClauseAlternate: (i: number) => void
}

function ClauseEditDialog(props: ClauseEditDialogProps) {
  const { t } = useTranslation()
  const edited = useRef(false)
  const values = findAlternateClauses(props.values).map(([_, v]) => v)
  const [newClause, setNewClause] = useState(props.values)
  const formRef = useRef<HTMLFormElement>(null)

  const formInvalid = () => !formRef.current?.checkValidity()

  // set the LastEditedClause when user is done editing and this component unmounts
  useEffect(
    () => () => {
      edited.current && props.setLastEditedClause(newClause)
    },
    [],
  )

  const handleChange = (field: string, value: string) => {
    edited.current = true
    const editClause = { ...newClause, [field]: value }
    setNewClause(editClause)
  }

  async function onConfirmChange() {
    await props.onChange?.(newClause)
    props.setHidden(true)
  }

  return (
    <ConfirmDialog
      title={props.title}
      hidden={props.hidden}
      disableBtn={props.saving || formInvalid()}
      confirm={props.confirmLabel}
      onConfirm={onConfirmChange}
      onDismiss={() => {
        props.onDismiss?.(newClause)
        props.setHidden(true)
      }}
    >
      <form ref={formRef} onSubmit={onConfirmChange}>
        <Stack style={{ gap: '1em' }}>
          <Stack.Item>
            <StyledTextField
              required
              multiline
              autoAdjustHeight
              label="Clause Title"
              name="clauseTitle"
              value={newClause.clause_title}
              onChange={event => handleChange('clause_title', event.currentTarget.value)}
            />
          </Stack.Item>
          <Stack.Item>
            <StyledTextField
              required
              multiline
              autoAdjustHeight
              label="Preferred Language"
              name="preferredLanguage"
              value={newClause.sample_standard_language_acceptable_position}
              onChange={event =>
                handleChange(
                  'sample_standard_language_acceptable_position',
                  event.currentTarget.value,
                )
              }
            />
          </Stack.Item>
          <Stack.Item>
            <StyledTextField
              required
              multiline
              autoAdjustHeight
              label="Clause Summary"
              name="clauseSummary"
              value={newClause.acceptable_position}
              onChange={event => handleChange('acceptable_position', event.currentTarget.value)}
            />
          </Stack.Item>

          <AddAnotherTextField
            multiline
            autoAdjustHeight
            maxFields={2}
            values={values}
            labelPrefix={t('page.Playbook Details.Alternative Clause')}
            addBtnLabel={t('page.Playbook Details.Add Alternative Clause')}
            btnStyles={{ alignSelf: 'end' }}
            onValueChange={values => {
              values.forEach((value, index) => {
                handleChange(`alternate_${index + 1}`, value)
              })
              edited.current = true
            }}
            onRemove={i => props.onRemoveClauseAlternate(i)}
          />
        </Stack>
      </form>
    </ConfirmDialog>
  )
}

type NewClauseDialogProps = {
  saving?: boolean
  hidden: boolean
  onDismiss: () => void
  onConfirm: (v: PlaybookBuilderStructuredOutput) => Promise<void> | void
  setHidden: Dispatch<SetStateAction<boolean>>
  onRemoveClauseAlternate: (index: number) => void
  setLastEditedClause: Dispatch<SetStateAction<PlaybookBuilderStructuredOutput | undefined>>
}

function NewClauseDialog(props: NewClauseDialogProps) {
  const { t } = useTranslation()
  const [newClause, setNewClause] = useState(buildNewEmptyClause())

  return (
    <ClauseEditDialog
      confirmLabel={t('button.Add New Clause')}
      label={''}
      multiline={true}
      saving={props.saving}
      hidden={props.hidden}
      setHidden={props.setHidden}
      onChange={values => {
        props.onConfirm(values)
        setNewClause(values)
      }}
      values={newClause}
      title={`${t('button.Add')} ${t('page.Playbook Details.Clause Content')}`}
      onDismiss={props.onDismiss}
      setLastEditedClause={props.setLastEditedClause}
      onRemoveClauseAlternate={i => props.onRemoveClauseAlternate(i)}
    />
  )
}

function findAlternateClauses(clause: PlaybookBuilderStructuredOutput) {
  return Object.entries(clause).filter(
    ([key, val]) =>
      key.startsWith('alternate') &&
      val?.trim() &&
      val.toLowerCase() !== EMPTY_CLAUSE_ALTERNATE.toLowerCase(),
  )
}

function buildManuallyAddedInstruction(value = ''): PlaybookContractInstruction {
  return {
    instruction: value,
    template_citation: '',
    source: PLAYBOOK_INSTRUCTION_ADDED_BY_USER,
    added: true,
  }
}

function validatePlaybook({
  playbookStatus,
  clauses,
  contract_instructions,
}: PlaybookContent & { playbookStatus: string }) {
  if (playbookStatus === 'PUBLISHED') return true

  return clauses.length + contract_instructions.length > 2
}

function buildNewEmptyClause(): PlaybookBuilderStructuredOutput {
  return {
    clause_title: '',
    template_citation: '',
    alternate_1: '',
    alternate_2: '',
    acceptable_position: '',
    sample_standard_language_acceptable_position: '',
  }
}
